import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import * as styles from "../../styles/blog.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Blog({ data }) {
  const { blog, contactInfo } = data
  const contact = contactInfo.siteMetadata.contact
  const myBlog = blog.nodes.map(b => {
    const image = getImage(b.frontmatter.thumbs)
    return (
      <div key={b.id}>
        <Link to={`/blog/${b.frontmatter.slug}`}>
          <div>
            <GatsbyImage image={image} alt={b.frontmatter.title} />
            <h3>{b.frontmatter.title}</h3>
            <p>{b.frontmatter.stack}</p>
          </div>
        </Link>
      </div>
    )
  })

  return (
    <Layout title="Blog">
      <div className={styles.blogs}>
        <h2>Blog</h2>
        <h3>Blogs I've Created</h3>
        <div className={styles.blog}>{myBlog}</div>
        <p>
          Like what you see?{" "}
          <strong>
            <a href={`mailto:${contact}`}>Email me</a>
          </strong>
        </p>
      </div>
    </Layout>
  )
}

// Export page query + siteMetadata query
export const query = graphql`
  query BlogPage {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { type: { eq: "blog" } } }
    ) {
      nodes {
        frontmatter {
          slug
          stack
          title
          thumbs {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        id
      }
    }
    contactInfo: site {
      siteMetadata {
        contact
      }
    }
  }
`
